@keyframes showErrors {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseMoment {
  0% {
    background-color: transparent;
  }

  30% {
    background-color: rgba(190, 190, 190, 0.12);
  }

  60% {
    background-color: rgba(190, 190, 190, 0.12);
  }

  100% {
    background-color: transparent;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spaceIn {
  from {
    transform: translate(40%, 0%);
    transform-origin: 50% 50%;
  }

  to {
    transform: translate(-50%, -5%);
    transform-origin: 50% 50%;
  }
}

@keyframes spaceOut {
  from {
    transform: translate(30%, 0%);
    transform-origin: 50% 50%;
  }

  to {
    transform: translate(-70%, -6%);
    transform-origin: 50% 50%;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    transform: translateX(-468px);
  }

  100% {
    transform: translateX(468px);
  }
}

@keyframes loader-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes loader-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes loader-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@keyframes progress-bar-move {
  0% {
    width: 0;
  }
  20% {
    width: 10%;
  }
  25% {
    width: 24%;
  }
  43% {
    width: 41%;
  }
  56% {
    width: 50%;
  }
  66% {
    width: 52%;
  }
  71% {
    width: 60%;
  }
  75% {
    width: 76%;
  }
  94% {
    width: 86%;
  }
  100% {
    width: 100%;
  }
}

@keyframes showList {
  0% {
    top: 25px;
    left: -10px;
    width: 0;
    height: 0;
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    top: 30px;
    left: 0;
    width: auto;
    height: auto;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hideList {
  0% {
    top: 30px;
    left: 0;
    width: auto;
    height: auto;
    opacity: 1;
    transform: scale(1);
  }

  100% {
    top: 25px;
    left: -10px;
    width: 0;
    height: 0;
    opacity: 0;
    transform: scale(0.5);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
