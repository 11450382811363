@import "destyle";
@import "destyle-custom";
@import "colors";
@import "variables";
@import "mixins";
@import "template";
@import "fonts";
@import "account-base";
@import "question-base";
// !libs
@import "toast";
@import "ng-select";
@import "tooltip";
@import "ngx-file-drop";
@import "ngx-image-cropper";
@import "ngx-datatable";
@import "scrollbar-custom";
@import "ngneat-dialog";
@import "ngx-valdemort";
@import "ng-inline-svg";
//
@import "./swiper.styles.scss";
@import "./tab-set.styles.scss";
@import "./tooltip.styles.scss";
//!
html {
  height: 100%;
  height: -webkit-fill-available;
  scroll-behavior: smooth;
  @include scrollbar-custom;
}

body {
  min-width: 300px;
  height: 100vh;
  min-height: -webkit-fill-available;
  background: url("/assets/img/shared/shared-bg.jpg") no-repeat center / cover
    #f6f7fc;

  &.is-hidden {
    overflow: hidden;
  }
}
