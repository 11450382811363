.tooltip.tooltip-shadow {
  background: var(--color-bg);
  @include font-primary(14px, $color-text-secondary, 400);

  @include for-small-desktop-up {
    @include font-primary(12px);
  }

  &.tooltip-top {
    &::after {
      border-color: var(--color-bg) transparent transparent;
    }
  }

  &.tooltip-right {
    &::after {
      border-color: transparent var(--color-bg) transparent transparent;
    }
  }

  &.tooltip-left {
    &::after {
      border-color: transparent transparent transparent var(--color-bg);
    }
  }
}

/* Permission-tooltip CSS */
.is-permission-active {
  cursor: not-allowed;
  user-select: none;

  .is-disabled {
    pointer-events: none;
  }
}

.permission-tooltip {
  position: absolute;
  z-index: 1500;
  padding: 10px;
  width: 100%;
  max-width: 200px;
  background-color: $background-color-septenary;
  border-radius: 5px;
  @include font-primary(14px, $color-text-secondary, 400);
  text-align: center;
  animation: fadeIn 0.5s;

  &::after {
    content: "";
    position: absolute;
    display: block;
  }

  &.is-left {
    &::after {
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      border-style: solid;
      border-width: 6px 0 6px 8px;
      border-color: transparent transparent transparent
        $background-color-septenary;
    }
  }

  &.is-right {
    &::after {
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      border-style: solid;
      border-width: 6px 8px 6px 0;
      border-color: transparent $background-color-septenary transparent
        transparent;
    }
  }

  &.is-center,
  &.is-center-bottom {
    &::after {
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-color: transparent;
    }
  }

  &.is-center {
    &::after {
      top: -8px;
      border-width: 0 6px 8px 6px;
      border-bottom-color: $background-color-septenary;
    }
  }

  &.is-center-bottom {
    &::after {
      bottom: -8px;
      border-width: 8px 6px 0 6px;
      border-top-color: $background-color-septenary;
    }
  }
}
