.tooltip-beta {
  position: absolute;
  background: var(--color-bg);
  color: #fff;
  font-weight: 400;
  text-align: center;
  max-width: 200px;
  padding: 5px 8px;
  border-radius: 6px;
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  pointer-events: none;
  z-index: 5555;
  opacity: 0;
  box-shadow: 0 7px 15px -5px #0006;
  transition: opacity ease 0.5;
  &.tooltip-show {
    opacity: 1;
  }
  &.tooltip-hidden {
    display: none !important;
  }

  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 5px;
  }
  &.tooltip-top {
    &::before {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: var(--color-bg) transparent transparent transparent;
    }
  }

  &.tooltip-right {
    &::before {
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent var(--color-bg) transparent transparent;
    }
  }

  &.tooltip-left {
    &::before {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent var(--color-bg);
    }
  }

  @media screen and (max-width: 1600px) {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
  }
}
