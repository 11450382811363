// swiper bundle styles
@import "swiper/css/bundle";

@import "swiper/css";

.swiper {
  .swiper-wrapper {
    box-sizing: border-box;
  }

  &.ration-swiper.ration-swiper-timecards {
    padding: 10px 5px;

    .swiper-wrapper {
      .swiper-slide {
        width: auto;
      }
    }
  }

  &.diary-swiper.diary-swiper-timecards {
    padding: 10px 5px;

    .swiper-wrapper {
      .swiper-slide {
        width: auto;
      }
    }
  }

  &.collect-recipe-swiper {
    overflow: initial;

    .swiper-wrapper {
      .swiper-slide {
        max-width: 295px;
      }
    }
  }

  &.ration-swiper.ration-swiper-report-cards {
    padding: 25px 30px;
    height: 100%;
    overflow-y: auto;

    @include for-small-desktop-up {
      padding: 15px 20px;
    }

    .swiper-wrapper {
      height: 100%;

      .swiper-slide {
        width: 100%;
        height: 100%;
        max-width: 365px;

        @include for-small-desktop-up {
          max-width: 250px;
        }
      }
    }
  }

  &.diary-swiper.diary-swiper-report-cards {
    padding: 25px 30px;
    height: 100%;
    overflow-y: auto;

    @include for-small-desktop-up {
      padding: 15px 20px;
    }

    .swiper-wrapper {
      height: 100%;

      .swiper-slide {
        width: 100%;
        height: 100%;
        max-width: 365px;

        @include for-tablet-portrait-up {
          max-width: none;
        }
      }
    }
  }

  &.food-category-swiper {
    padding: 10px 5px;
  }

  .swiper-wrapper {
    .swiper-slide {
      width: auto;
    }
  }
}
