.ngx-file-drop.cropper__drop {
  .ngx-file-drop__drop-zone {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $color-border-primary;
    border-radius: $border-radius;
  }

  .ngx-file-drop__content {
    height: auto;
  }
}
