@import "mixins";
@import "variables";

$active-tab-color: red !default;
$default-tab-border: solid 1px transparent !default;
$tab-border-transition-duration: 200ms !default;
$tab-border-transition-timing-function: ease-out !default;
$nav-tab-padding: 10px !default;
$disabled-tab-opacity: 0.5 !default;
$tab-cursor: pointer !default;

.nav-tabset:not(.disable-style) {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;

  .nav-tab {
    padding: $nav-tab-padding;
    border-bottom: $default-tab-border;
    transition: border $tab-border-transition-duration
      $tab-border-transition-timing-function;

    > .tab-subtitle {
      display: block;
      font-size: 0.7rem;
    }

    &.active {
      border-color: $active-tab-color;
    }

    &.disabled {
      opacity: $disabled-tab-opacity;
    }

    &:not(.active):not(.disabled) {
      cursor: $tab-cursor;
    }
  }
}

.app-tabset {
  display: block;

  .nav-tabset {
    padding: 15px 25px;
    flex-wrap: wrap;
    box-shadow: 0 3px 5px rgba($color-box-shadow-primary, 0.06);

    @include for-tablet-portrait-up {
      padding-left: 20px;
      padding-right: 20px;
    }

    .nav-tab {
      padding: 5px 0;

      &:not(:last-child) {
        margin-right: 20px;
      }

      span {
        @include font-primary(16px, $color-text-primary, 400);
      }

      &.active {
        border-color: var(--color-bg);

        span {
          color: var(--color-bg);
        }
      }
    }
  }
}
