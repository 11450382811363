@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Thin",
  100,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-ThinItalic",
  100,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Ultralight",
  200,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic",
  200,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Light",
  300,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-LightItalic",
  300,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Regular",
  400,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-RegularItalic",
  400,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Medium",
  500,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-MediumItalic",
  500,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Semibold",
  600,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic",
  600,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Bold",
  700,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-BoldItalic",
  700,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Black",
  900,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-BlackItalic",
  900,
  italic
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-Heavy",
  900,
  normal
);
@include font-face(
  "SF Pro Display",
  "/assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic",
  900,
  italic
);
