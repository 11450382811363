.ngx-val-errors {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__text {
    display: flex;
    @include font-primary(11px, $color-text-error, 400);
    text-align: left;
  }
}
