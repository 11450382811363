@mixin scrollbar-custom {
  *::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  *::-webkit-scrollbar-track {
    background: #eeeaea;
    border: none;
    border-radius: 10px;
    &:hover {
      background: #eeeaea;
    }
    &:active {
      background: #eeeaea;
    }
  }
  *::-webkit-scrollbar-thumb {
    background: #c1c0c0;
    border: 2px solid #eeeaea;
    border-radius: 10px;
    transition: all $time-transition;
    &:hover {
      background: #cdcdcd;
    }
    &:active {
      background: #cdcdcd;
    }
  }
  *::-webkit-scrollbar-corner {
    background: transparent;
  }
  * {
    scrollbar-width: thin;
  }
}
