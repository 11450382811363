.ngx-datatable {
  .datatable-body-row--selectable {
    cursor: pointer;
  }
}

.ngx-datatable.account-ration,
.ngx-datatable.secondary-table {
  width: 100%;
  min-width: 950px;
  overflow: visible !important;
  display: flex;

  .datatable-body .datatable-scroll {
    display: block;
  }

  .datatable-body .datatable-body-row > div {
    display: flex;
    max-width: 100%;
  }

  .datatable-scroll {
    width: 100% !important;
  }

  .datatable-scroller {
    width: 100% !important;
  }

  .datatable-body-row,
  .datatable-scroll {
    max-width: 100%;
    min-width: 100%;
  }

  .datatable-header {
    background-color: $background-color-primary;
    border-top: 1px solid $color-border-primary;
    box-shadow: 1px 1px 5px rgba($color-box-shadow-primary, 0.09);

    .datatable-header-cell .datatable-header-cell-template-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .datatable-header-cell {
    &:not(:first-child) {
      border-left: 1px solid $color-border-primary;
    }

    padding: 10px 15px;
    display: flex;
    align-items: center;
    @include font-primary(16px, $color-text-primary, 500);
    letter-spacing: 0.55px;

    .resize-handle {
      border-right: 3px solid var(--color-bg);
    }

    .sort-btn {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      &.datatable-icon-sort-unset {
        display: flex;

        &:before,
        &:after {
          content: "";
          width: 0;
          height: 0;
          display: flex;
          border-style: solid;
        }

        &:before {
          border-width: 0 5px 6px 5px;
          border-color: transparent transparent $color-border-tertiary
            transparent;
        }

        &:after {
          margin-top: 2px;
          border-width: 6px 5px 0 5px;
          border-color: $color-border-tertiary transparent transparent
            transparent;
        }
      }

      &.datatable-icon-up.sort-asc,
      &.datatable-icon-down.sort-desc {
        display: flex;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &.datatable-icon-up.sort-asc {
        border-width: 0 5px 6px 5px;
        border-color: transparent transparent $color-border-tertiary transparent;
      }

      &.datatable-icon-down.sort-desc {
        border-width: 6px 5px 0 5px;
        border-color: $color-border-tertiary transparent transparent transparent;
      }
    }
  }

  .datatable-row-wrapper {
    margin-top: 10px;
  }

  .datatable-body-row {
    border-top: 1px solid $color-border-primary;
    border-bottom: 1px solid $color-border-primary;
    background-color: $background-color-quaternary;
    transition: all $time-transition;
    cursor: pointer;

    &:hover {
      background-color: $background-color-senary;
    }
  }

  .datatable-body-cell {
    overflow-x: visible;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;

    &:not(:first-child) {
      border-left: 1px solid $color-border-primary;
    }

    &:last-child {
      padding: 10px;
    }

    .datatable-body-cell-label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.ngx-datatable.account-ration {
  &--favorite {
    .datatable-body-row {
      cursor: default;
    }
  }
}

.ngx-datatable.table-primary {
  width: 100%;
  box-shadow: 1px 1px 5px rgba($color-box-shadow-primary, 0.1);

  .visible {
    width: min-content;
  }

  .datatable-row-group {
    &:not(:first-child) {
      border-top: 1px solid $color-border-primary;
    }
  }

  .datatable-body .datatable-scroll {
    display: block;
    width: 100% !important;
  }

  .datatable-header {
    background-color: var(--color-bg);
    box-shadow: 1px 1px 5px rgba($color-box-shadow-primary, 0.09);
    position: relative;
    z-index: 200;

    .datatable-header-cell .datatable-header-cell-template-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .datatable-header-cell {
    border-top: 1px solid $color-border-primary;
    border-left: 1px solid $color-border-primary;

    &:last-child {
      border-right: 1px solid $color-border-primary;
    }

    padding: 15px 20px;
    display: flex;
    align-items: center;
    @include font-primary(16px, $color-text-secondary, 500);
    letter-spacing: 0.55px;

    @include for-tablet-portrait-up {
      @include font-primary(14px);
    }
  }

  .datatable-row-wrapper {
    &:last-child {
      .datatable-body-row {
        border-bottom: 1px solid $color-border-primary;
      }
    }
  }

  .empty-row {
    padding: 20px;
    @include font-primary(16px, $color-text-primary, 400);
    text-align: center;
    letter-spacing: 0.48px;
  }

  .datatable-body-cell {
    padding: 15px 20px;
    border-left: 1px solid $color-border-primary;
    @include for-tablet-landscape-up {
      padding: 24px 20px;
    }

    &:last-child {
      border-right: 1px solid $color-border-primary;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-color-primary;

    .datatable-body-cell-label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .table__cell-text {
      @include for-tablet-landscape-up {
        @include font-primary(18px);
      }
      @include for-tablet-portrait-up {
        @include font-primary(12px);
      }
    }
  }
}

.ngx-datatable.table-tertiary {
  .table {
    &__header-cell {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__header-cell-button {
      margin-left: 15px;
    }

    &__cell-text {
      @include font-primary(14px, $color-text-primary, 400);
      letter-spacing: 0.48px;
    }

    &__cell-button {
      width: 100%;
      display: flex;
    }

    &__cell-control {
      position: relative;
      display: flex;
      align-items: center;
    }

    &__cell-control-input {
      width: 100%;
      display: flex;
      @include font-primary(14px, $color-text-primary, 400);
      letter-spacing: 0.48px;
      @include text-ellipsis;

      &:focus {
        border-bottom: 1px solid $color-border-attention;
      }
    }

    &__cell-control-button {
      margin-left: 15px;
      display: flex;
    }
  }
}

.ngx-datatable.table-secondary {
  width: 100%;
  min-width: 1050px;
  overflow: visible !important;
  display: flex;
  border-bottom: 1px solid $color-border-primary;

  .datatable-body .datatable-scroll {
    display: block;
  }

  .datatable-scroll {
    width: 100% !important;
  }

  .datatable-scroller {
    width: 100% !important;
  }

  .datatable-body-row,
  .datatable-scroll {
    max-width: 100%;
    min-width: 100%;
  }

  .table {
    &__header-cell {
      cursor: pointer;
      @include font-primary(14px, $color-text-primary, 500);
      letter-spacing: 0.48px;
    }

    &__cell-text {
      @include font-primary(13px, $color-text-primary, 300);
      letter-spacing: 0.44px;
    }

    &__cell-action {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $background-color-primary;
      border-radius: 50%;
      box-shadow: 1px 1px 2px rgb(0 0 0 / 9%);

      &:hover {
        color: var(--color-bg);
      }

      &--link {
        transition: color $time-transition;
      }
    }

    &__icon {
      width: 15px;
      height: 15px;
    }

    &__wrapper-button {
      margin-left: 10px;
    }

    &__cell-info {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  .datatable-header {
    position: relative;
    z-index: 200;
    background-color: $background-color-primary;
    // border: 1px solid $color-border-primary;
    box-shadow: 1px 1px 5px rgba($color-box-shadow-primary, 0.09);
    overflow: visible;

    .datatable-header-cell {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      background-color: $background-color-primary;

      &:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: 100;
      }

      &:not(:last-child) {
        border-right: 1px solid $color-border-primary;
      }

      .datatable-header-cell-template-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .resize-handle {
        border-right: 3px solid var(--color-bg);
      }

      .sort-btn {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        &.datatable-icon-sort-unset {
          display: flex;

          &:before,
          &:after {
            content: "";
            width: 0;
            height: 0;
            display: flex;
            border-style: solid;
          }

          &:before {
            border-width: 0 5px 6px 5px;
            border-color: transparent transparent $color-border-tertiary
              transparent;
          }

          &:after {
            margin-top: 2px;
            border-width: 6px 5px 0 5px;
            border-color: $color-border-tertiary transparent transparent
              transparent;
          }
        }

        &.datatable-icon-up.sort-asc,
        &.datatable-icon-down.sort-desc {
          display: flex;
          width: 0;
          height: 0;
          border-style: solid;
        }

        &.datatable-icon-up.sort-asc {
          border-width: 0 5px 6px 5px;
          border-color: transparent transparent $color-border-tertiary
            transparent;
        }

        &.datatable-icon-down.sort-desc {
          border-width: 6px 5px 0 5px;
          border-color: $color-border-tertiary transparent transparent
            transparent;
        }
      }
    }
  }

  .datatable-body-cell {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      .table__cell-text {
        text-align: center;
      }
    }

    &:nth-child(1) {
      position: sticky;
      z-index: 100;
      left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid $color-border-primary;
    }

    .datatable-body-cell-label {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  .datatable-body-row {
    background-color: $background-color-primary;

    .datatable-body-cell {
      background-color: $background-color-primary;
    }

    &.datatable-row-odd {
      background-color: $background-color-primary;

      .datatable-body-cell {
        background-color: $background-color-primary;
      }
    }

    &.active {
      background-color: var(--color-bg);

      .datatable-body-cell {
        background-color: var(--color-bg);
      }

      .table__wrapper-button {
        cursor: not-allowed;
      }

      .table__cell-action--select {
        pointer-events: none;
        color: $color-svg-disabled;
        background-color: $background-color-button-disabled;
      }

      .table__cell-text {
        @include font-primary(13px, $color-text-secondary, 300);
      }
    }
  }

  .datatable-row-wrapper {
    &:not(:first-child) {
      .datatable-body-row {
        border-top: 1px solid $color-border-primary;
      }
    }
  }
}

.ngx-datatable {
  .correction {
    .datatable-body-cell-label {
      color: $color-text-quinary;
    }
  }

  .normal {
    .datatable-body-cell-label {
      color: $color-text-tertiary;
    }
  }

  .diagnostics {
    .datatable-body-cell-label {
      color: $color-text-attention;
    }
  }

  .diet {
    .datatable-body-cell-label {
      color: $color-text-quaternary;
    }
  }

  .normal-level {
    color: $color-text-tertiary;

    .app-svg-icon {
      display: none;
    }
  }

  .base-level {
    color: $color-text-primary;

    .app-svg-icon {
      display: none;
    }
  }

  .low-level {
    color: $color-text-attention;
  }

  .high-level {
    color: $color-text-attention;

    .app-svg-icon {
      transform: rotate(180deg);
      display: flex;
    }
  }
}

.ngx-datatable.vitamins-table {
  .visible {
    width: 100%;
  }

  .datatable {
    &-row-wrapper {
      margin-top: 0;
    }

    &-body {
      &-cell-label {
        position: relative;
        @include font-primary(13px, 300);
        letter-spacing: 0.44px;
      }

      &-row {
        background-color: $background-color-primary;
      }
    }
  }

  .table {
    &-wraper {
      margin-top: 10px;
    }
  }
}
