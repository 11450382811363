@import "variables";
@import "mixins";
@import "template";

.questions-header {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 25px 15px 20px;
  border-bottom: 1px solid $color-border-primary;
  background-color: $background-color-primary;
  box-shadow: 1px -1px 4px rgba($color-box-shadow-primary, 0.07);
  border-radius: $border-radius $border-radius 0 0;
  @include for-tablet-landscape-up {
    padding: 18px 15px;
  }
  @include for-tablet-portrait-up {
    padding: 11px 15px;
  }

  &__title {
    @include font-primary(16px, $color-text-primary, 500);
    text-transform: uppercase;
    @include for-tablet-portrait-up {
      @include font-primary(14px, false, false, 17px);
    }
  }

  &__controls {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__controls-item {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__controls-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba($color-box-shadow-primary, 0.12);
    transition: box-shadow $time-transition;

    @include for-tablet-landscape-up {
      width: 32px;
      height: 32px;
    }
    @include for-tablet-portrait-up {
      width: 20px;
      height: 20px;
    }

    &:hover {
      box-shadow: 1px 1px 4px rgba($color-box-shadow-primary, 0.2);
    }

    &:active {
      position: relative;
      top: 1px;
    }
  }

  &__controls-icon {
    width: 11px;
    height: 11px;

    &--left {
      transform: rotate(90deg);
    }

    &--right {
      transform: rotate(-90deg);
    }

    &--big {
      width: 17px;
      height: 9px;
    }
  }
}

%base-body {
  position: relative;
  padding: 30px 35px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0 0 $border-radius $border-radius;
  background-color: $background-color-primary;
  box-shadow: 1px 1px 4px rgba($color-box-shadow-primary, 0.07);
  @include for-tablet-portrait-up {
    padding: 20px 15px 30px;
  }
}

%base-action-button {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

%base-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

%base-width-inner {
  width: 100%;
  max-width: 950px;
}

%base-question-container {
  width: 100%;
  max-width: 1260px;
}

%base-list-gap {
  margin-top: 35px;
  grid-gap: 30px 15px;
  @include for-tablet-landscape-up {
    margin-top: 15px;
    grid-row-gap: 15px;
  }
}

%base-title {
  max-width: 700px;
  @include font-primary(16px, $color-text-primary, 500);
  text-align: center;
  @include for-tablet-portrait-up {
    @include font-primary(14px);
  }
}

%base-text {
  margin-top: 10px;
  @include font-primary(14px, $color-text-primary, 300);
  font-style: italic;
  text-align: center;
}

%base-content-with-checkbox {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px 15px;

  @include for-tablet-landscape-up {
    grid-row-gap: 15px;
  }
  @include for-tablet-portrait-up {
    grid-template-columns: 1fr;
  }
}
