@import "@ng-select/ng-select/themes/default.theme.css";
@import "variables";

.ng-select.ng-select-disabled > .ng-select-container {
  cursor: not-allowed;
}

.select-primary {
  width: 100%;

  /* -------------------- состояния --------------------*/
  &.ng-select-disabled {
    cursor: no-drop;
    opacity: 0.5;
  }

  &.ng-select.ng-select-disabled .ng-arrow-wrapper {
    cursor: not-allowed;
  }

  &.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $border-color-form-control-border;
    box-shadow: none;
  }

  &.ng-invalid.ng-touched .ng-select-container,
  &.ng-invalid.ng-dirty .ng-select-container,
  &.ng-invalid.ng-touched.ng-select-focused .ng-select-container,
  &.ng-invalid.is-invalid .ng-select-container {
    border: 1px solid $border-color-form-control-error;
  }

  /* -------------------- ng-select-container  --------------------*/
  &.ng-select .ng-select-container {
    min-height: 0;
    border-radius: $border-radius;
    background-color: $background-color-form-control-primary;
    border: 1px solid $border-color-form-control-border;
    transition: border 0.3s;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid var(--color-bg);
    }

    .ng-value-container {
      padding-left: 0;
      .ng-placeholder {
        @include font-primary(
          14px,
          rgba($color-form-control-placeholder-primary, 0.6)
        );
        font-style: italic;
        @include text-ellipsis;
      }
    }
  }

  /* -------------------- ng-select-opened  --------------------*/
  &.ng-select.ng-select-opened > .ng-select-container {
    background-color: $background-color-primary;
    border-color: var(--color-bg);

    .ng-arrow {
      top: 5px;
      border-color: $color-border-tertiary;
      border-width: 0 1px 1px 0;
      transform: rotate(-135deg);
    }
  }

  /* -------------------- ng-select-single/ng-select-multiple  --------------------*/
  &.ng-select.ng-select-single .ng-select-container,
  &.ng-select.ng-select-multiple .ng-select-container {
    margin-top: 0;
    padding: 10px 15px;
    height: auto;
  }

  &.ng-select.ng-select-multiple {
    width: 100%;
    .ng-select-container {
      .ng-value-container {
        margin-bottom: -5px;
        padding-top: 0;
        align-items: baseline;

        .ng-input {
          margin-top: -8px;
          padding: 0;
        }
      }
      .ng-value {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        background-color: var(--color-bg-a20);

        .ng-value-icon.left {
          padding: 1px 5px;
          color: $color-text-error;
          border-right: none;

          &:hover {
            background-color: transparent;
          }
        }

        .ng-value-label {
          display: flex;
          @include font-primary(14px, $color-label-text-primary, 300);
        }
      }
      .ng-placeholder {
        top: 10px;
        padding: 0;
      }
    }
    &.ng-select-disabled
      > .ng-select-container
      .ng-value-container
      .ng-value
      .ng-value-icon {
      display: flex;
    }
  }

  /* ---------- input  ----------*/
  &.ng-select .ng-select-container .ng-value-container .ng-input > input {
    @include font-primary(14px, $color-text-primary, 300);
  }

  &.ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: 8px;
    padding: 0 10px;
  }

  /* ---------- выпадашка ----------*/
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    background: var(--color-button-bg-before);
    @include font-primary(false, $color-text-secondary);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: var(--color-bg-a20);
  }

  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected
    .ng-option-label,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked
    .ng-option-label {
    @include font-primary(14px, $color-text-secondary, 300);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    @include for-tablet-portrait-up {
      max-height: 200px;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 12px 15px;
    @include font-primary(14px, $color-text-primary, 300);
    white-space: normal;
  }

  .ng-dropdown-panel {
    margin-top: 2px;
    @include font-primary(14px, $color-form-control-text-primary);
    border: 1px solid $border-color-form-control-border;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: none;
    overflow: hidden;
  }

  .ng-dropdown-panel.ng-select-bottom
    .ng-dropdown-panel-items
    .ng-option:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* ---------- label ----------*/
  .ng-value-label {
    @include font-primary(14px, $color-label-text-primary, 300);
  }

  /* ---------- arrow ----------*/
  &.ng-select .ng-arrow-wrapper {
    top: -3px;
    margin-right: 0;
    margin-left: 5px;
    padding-right: 0;
    width: 14px;

    .ng-arrow {
      border-color: $color-border-tertiary;
      border-style: solid;
      border-width: 0 1px 1px 0;
      padding: 3px;
      transform: rotate(45deg);
      opacity: 0.8;
    }
  }

  // &.ng-select .ng-clear-wrapper {
  //   display: none;
  // }
}

.select-secondary {
  @extend .select-primary;

  &.ng-select .ng-select-container {
    border-radius: 50px;
  }

  &.ng-select.ng-select-opened .ng-select-container {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.select-simple {
  width: 145px;
  cursor: pointer;

  /* -------------------- ng-select-container  --------------------*/
  &.ng-select .ng-select-container {
    min-height: 0;
    //border-radius: $border-radius;
    background-color: transparent;
    border: none;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .ng-value-container {
      padding-left: 0;
      .ng-placeholder {
        @include font-primary(
          14px,
          rgba($color-form-control-placeholder-primary, 0.6)
        );
        font-style: italic;
      }
    }
  }

  /* -------------------- ng-select-opened  --------------------*/
  &.ng-select.ng-select-opened > .ng-select-container {
    .ng-arrow {
      top: 5px;
      border-color: $color-border-tertiary;
      border-width: 0 1px 1px 0;
      transform: rotate(-135deg);
    }
  }

  /* -------------------- ng-select-single/ng-select-multiple  --------------------*/
  &.ng-select.ng-select-single .ng-select-container {
    margin-top: 0;
    height: auto;
  }

  /* ---------- input  ----------*/
  &.ng-select .ng-select-container .ng-value-container .ng-input > input {
    @include font-primary(14px, $color-text-primary, 300);
  }

  &.ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: auto;
    padding: 0 10px;
    cursor: pointer;
  }

  /* ---------- выпадашка ----------*/
  &.ng-dropdown-panel {
    left: 0 !important;
    margin-top: 12px;
    width: 100% !important;
    @include font-primary(14px, $color-form-control-text-primary);
    border: 1px solid $border-color-form-control-border;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: none;
    overflow: hidden;
  }

  &.ng-dropdown-panel.ng-select-bottom {
    margin-top: 12px;
  }

  &.ng-dropdown-panel.ng-select-top {
    margin-top: 0;
    margin-bottom: 12px;
    border-radius: $border-radius $border-radius 0 0;
  }

  &.ng-dropdown-panel.ng-select-bottom
    .ng-dropdown-panel-items
    .ng-option:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  &.ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    background: var(--color-button-bg-before);
  }

  &.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--color-bg-a20);
  }

  &.ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected
    .ng-option-label,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked
    .ng-option-label {
    @include font-primary(14px, $color-text-secondary, 300);
  }

  &.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 12px 15px;
    @include font-primary(14px, $color-text-primary, 300);
  }

  /* ---------- label ----------*/
  .ng-value-label {
    @include font-primary(14px, $color-label-text-primary, 300);
  }

  /* ---------- arrow ----------*/
  &.ng-select .ng-arrow-wrapper {
    top: -3px;
    margin-right: 0;
    margin-left: 5px;
    padding-right: 0;
    width: 14px;

    .ng-arrow {
      border-color: $color-border-tertiary;
      border-style: solid;
      border-width: 0 1px 1px 0;
      padding: 3px;
      transform: rotate(45deg);
      opacity: 0.8;
    }
  }

  // &.ng-select .ng-clear-wrapper {
  //   display: none;
  // }
}

/* ---------- выпадашка ----------*/
.ng-dropdown-panel {
  margin-top: 12px;
  @include font-primary(14px, $color-form-control-text-primary);
  border: 1px solid $border-color-form-control-border;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: none;
  overflow: hidden;
}

.ng-dropdown-panel.ng-select-top {
  margin-top: 0;
  margin-bottom: 2px;
  border-radius: $border-radius $border-radius 0 0;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 2px;
}

.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background: var(--color-button-bg-before);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--color-bg-a20);
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  @include font-primary(14px, $color-text-secondary, 300);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 12px 15px;
  @include font-primary(14px, $color-text-primary, 300);
}

/* ---------- label ----------*/
.ng-value-label {
  @include font-primary(14px, $color-label-text-primary, 300);
}

/* ---------- arrow ----------*/
.ng-select .ng-arrow-wrapper {
  top: -3px;
  margin-right: 0;
  margin-left: 5px;
  padding-right: 0;
  width: 14px;

  .ng-arrow {
    border-color: $color-svg-primary;
    border-style: solid;
    border-width: 0 1px 1px 0;
    padding: 3px;
    transform: rotate(45deg);
    opacity: 0.8;
  }
}

.select-value-wrap {
  &.ng-select.ng-select-single .ng-select-container {
    .ng-value-container {
      .ng-value {
        white-space: normal;
      }
    }
  }
}
