/*
! Эти цвета не трогаем
! Если нужен новый цвет название берем отсюда
! http://chir.ag/projects/name-that-color
! https://colorscheme.ru/color-converter.html
*/

$_white: #ffffff;
$_black: #000000;
$_mercury: #e2e2e2;
$_aqua-haze: #f8fafb;
$_mystic: #e3e8ef;
$_pomegranate: #f53939;
$_radical-red: #ff3471;
$_bittersweetapprox: #ff6262;
$_ocean-green: #44b96d;
$_mantis: #7ecd54;
$_mariner: #1e70c7;
$_dodger-blue: #1dc4f9;
$_anakiwa: #aedcff;
$_lightning-yellow: #ffc625;
$_neon_carrot: #ff922b;
$_cornflower-blue: #766aff;
$_mauve: #e4b6fb;
$_medium-purple: #bb43e3;
$_lavender: #cb7ce5;

// ! С этими работаем, придумай название для чего он и используем именно его
$background-color-primary: $_white;
$background-color-secondary: $_black;
$background-color-tertiary: $_ocean-green;
$background-color-quaternary: $_aqua-haze;
$background-color-quinary: $_lightning-yellow;
$background-color-senary: $_mercury;
$background-color-septenary: $_radical-red;
$background-color-octonary: $_dodger-blue;
$background-color-vigenary: $_mariner;
$background-color-nonary: $_mantis;
$background-color-denarius: $_mauve;
$background-color-duodenary: $_bittersweetapprox;
$background-color-attention: $_pomegranate;
// цвета текста
$color-text-primary: $_black;
$color-text-secondary: $_white;
$color-text-tertiary: $_ocean-green;
$color-text-quaternary: $_dodger-blue;
$color-text-quinary: $_lightning-yellow;
$color-text-error: $_pomegranate;
$color-text-attention: $_pomegranate;
$color-text-disabled: rgba($_black, 0.5);
// button
$color-button-text-primary: $_white;
$color-button-text-secondary: $_black;
$background-color-button-simple: $_white;
$background-color-button-primary: $_ocean-green;
$background-color-button-gradient: linear-gradient(
  -76deg,
  $_ocean-green 0%,
  $_mantis 100%
);
$background-color-button-gradient-revert: linear-gradient(
  -76deg,
  $_mantis 0%,
  $_ocean-green 100%
);
$background-color-button-gradient-secondary: linear-gradient(
  -76deg,
  $_radical-red 0%,
  $_bittersweetapprox 100%
);
$background-color-button-gradient-secondary-revert: linear-gradient(
  -76deg,
  $_bittersweetapprox 0%,
  $_radical-red 100%
);
$background-color-button-gradient-tertiary: linear-gradient(
  -80deg,
  $_mariner 0%,
  $_dodger-blue 100%
);
$background-color-button-gradient-tertiary-revert: linear-gradient(
  -80deg,
  $_dodger-blue 0%,
  $_mariner 100%
);
$background-color-button-gradient-quaternary: linear-gradient(
  -80deg,
  $_anakiwa 0%,
  $_dodger-blue 100%
);
$background-color-button-gradient-quaternary-revert: linear-gradient(
  -80deg,
  $_dodger-blue 0%,
  $_anakiwa 100%
);
$background-color-button-gradient-quinary: linear-gradient(
  -80deg,
  $_lavender 14%,
  $_medium-purple 100%
);
$background-color-button-gradient-quinary-revert: linear-gradient(
  -80deg,
  $_medium-purple 14%,
  $_lavender 100%
);
$background-color-gradient-quaternary: linear-gradient(
  -80deg,
  $_mauve 0%,
  $_cornflower-blue 100%
);
$background-color-gradient-quinary: linear-gradient(
  -80deg,
  $_neon_carrot 0%,
  $_lightning-yellow 100%
);
$background-color-gradient-quinary-revert: linear-gradient(
  -80deg,
  $_lightning-yellow 0%,
  $_neon_carrot 100%
);
$background-color-button-disabled: $_aqua-haze;
// form-control
$color-form-control-text-primary: $_black;
$color-form-control-placeholder-primary: $_black;
$background-color-form-control-primary: $_aqua-haze;
$background-color-form-control-selected: $_mantis;
$border-color-form-control-border: $_mystic;
$border-color-form-control-focus: $_ocean-green;
$border-color-form-control-error: $_pomegranate;
$color-label-text-primary: $_black;
// icons
$color-svg-primary: $_black;
$color-svg-secondary: $_white;
$color-svg-tertiary: $_ocean-green;
$color-svg-attention: $_pomegranate;
$color-svg-quaternary: $_radical-red;
$color-svg-quinary: $_lightning-yellow;
$color-svg-senary: $_dodger-blue;
$color-svg-septenary: $_mercury;
$color-svg-disabled: rgba($_black, 0.5);
$color-svg-secondary-brand: $_mariner;
// border
$color-border-primary: $_mercury;
$color-border-secondary: $_ocean-green;
$color-border-tertiary: $_black;
$color-border-quaternary: $_white;
$color-border-quinary: $_mystic;
$color-border-attention: $_pomegranate;
$color-border-secondary-brand: $_mariner;
// box-shadow
$color-box-shadow-primary: $_black;
$color-box-shadow-default: $_ocean-green;
$box-shadow: 2px 2px 4px rgba($color-box-shadow-primary, 0.14);
$box-shadow-default: 2px 2px 6px rgba($_ocean-green, 0.45);
$box-shadow-attention: 2px 2px 6px rgba($_radical-red, 0.45);
$box-shadow-secondary: 2px 2px 6px rgba($_mariner, 0.45);
$box-shadow-tertiary: 2px 2px 6px rgba($_lavender, 0.45);

// другое
$border-radius: 5px;
$time-transition: 0.3s;
