.svg-icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
