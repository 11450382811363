@import "ngx-toastr/toastr";
@import "mixins";

.toast-message {
  @include font-primary(16px, $color-text-secondary, 500);
  @include for-tablet-portrait-up {
    font-size: 14px;
  }
}

.toast-container {
  &.toast-bottom-right {
    @include for-tablet-portrait-up {
      bottom: auto;
      top: 12px;
    }
  }
}

.background-secondary-brand.toast-success {
  background-color: $background-color-vigenary;
}
