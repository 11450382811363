@import "variables";

// =============================================================================
// String Replace
// =============================================================================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;
  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );
  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );
  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }
  @font-face {
    font-family: quote($name);
    font-weight: $weight;
    font-style: $style;
    src: $src;
    font-display: auto;
  }
}

@mixin font-primary(
  $size: false,
  $colour: false,
  $weight: false,
  $lh: false,
  $style: false
) {
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $style {
    font-style: $style;
  }
}

//Media mixin desktop first
@mixin for-big-desktop-up {
  @media (max-width: 1800px) {
    @content;
  }
}

//@mixin for-middle-desktop-up {
//  @media (max-width: 1600px) {
//    @content;
//  }
//}

@mixin for-small-desktop-up {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin for-smalling-desktop-up {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin for-middle-tablet-up {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin no-scroll-bar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
    background: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    width: 0;
    height: 0;
  }
}

@mixin fix-scroll-bar-margin {
  &::before,
  &::after {
    content: "";
    display: table;
  }
}

@mixin invisible {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  // transition: all 0.25s;
}

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

// Overflow Ellipsis
@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin text-ellipsis-1row {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin text-ellipsis-2row {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin hyphenation {
  word-break: break-all;
  hyphens: auto;
}

// mixin для псевдоэлемента
@mixin pseudo($content: "", $position: absolute, $display: block) {
  content: $content;
  position: $position;
  display: $display;
}

// для placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

//для фоток
@mixin img {
  object-fit: cover;
  object-position: center center;
  display: flex;
  width: 100%;
  height: 100%;
}
