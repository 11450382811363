@import "variables";
@import "mixins";
@import "template";
@import "animation";

.additional-info {
  padding: 21px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 5px rgba($color-box-shadow-primary, 0.06);
  cursor: pointer;

  @include for-tablet-portrait-up {
    padding: 21px 20px;
  }

  &__title {
    @include font-primary(16px, $color-text-primary, 500);
  }
}
