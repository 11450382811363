:root {
  --color-bg: #44b96d;
  --color-bg-a20: rgba(68, 185, 109, 0.2);
  --color-bg-a60: rgba(68, 185, 109, 0.6);
  --color-bg-second: #7ecd54;
  --color-button-bg-after: linear-gradient(-76deg, #44b96d 0%, #7ecd54 100%);
  --color-button-bg-before: linear-gradient(-76deg, #7ecd54 0%, #44b96d 100%);
  --color-box-shadow: 2px 2px 6px rgba(#44b96d, 0.45);
}

.background-secondary-brand {
  --color-bg: #1e70c7;
  --color-bg-a20: rgba(30, 112, 199, 0.2);
  --color-bg-a60: rgba(30, 112, 199, 0.6);
  --color-bg-second: #1dc4f9;
  --color-button-bg-after: linear-gradient(-80deg, #1e70c7 0%, #1dc4f9 100%);
  --color-button-bg-before: linear-gradient(-80deg, #1dc4f9 0%, #1e70c7 100%);
  --color-box-shadow: 2px 2px 6px rgba(#1e70c7, 0.45);
}
