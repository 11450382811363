ngneat-dialog {
  .ngneat-dialog-backdrop {
    &.ngneat-dialog-backdrop-visible {
      overflow: auto;
      backdrop-filter: blur(2px);
      background-color: rgba($background-color-secondary, 0.15);
      @include for-tablet-portrait-up {
        padding: 30px 10px;
      }

      @supports not (backdrop-filter: blur(2px)) {
        background-color: rgba($background-color-secondary, 0.85);
      }

      .ngneat-dialog-content {
        overflow: visible !important;
        width: auto !important;
        min-height: 0 !important;
        color: transparent;
        background: transparent;
        border-radius: 0;
        box-shadow: none;
      }

      .ngneat-close-dialog {
        width: 45px;
        height: 45px;
        top: -20px;
        right: -20px;
        color: $color-svg-attention;
        background-color: $background-color-primary;
        border-radius: 50%;
        box-shadow: 1px 1px 4px rgba($color-box-shadow-primary, 0.15);
        @include for-tablet-portrait-up {
          right: -10px;
        }

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

// .ngneat-dialog-hidden {
//   overflow: visible !important;
// }
