/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 1) inset !important;
}

// !reset input[type=number]
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// disable focus
// *:focus {
//   outline: 0 transparent;
// }

/* Change autocomplete styles in WebKit */
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid transparent;
//   -webkit-text-fill-color: transparent;
//   -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0, 1) inset;
//   transition: background-color 5000s ease-in-out 0s;
// }
